import React, { useEffect, useState } from "react";
import UserContext from "../context/UserContex";

const UserProvider = ({ children }) => {
	const [user, setUser] = useState("Not Yet");

	useEffect(() => {
		const storedUser =
			localStorage.getItem("user") || sessionStorage.getItem("user");
		if (storedUser) {
			setUser(JSON.parse(storedUser));
		} else {
			setUser(0);
		}
	}, []);

	return (
		<UserContext.Provider value={{ user, setUser }}>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;
