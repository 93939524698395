import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Package = ({
	name,
	limit,
	price,
	selectedPackage,
	setSelectedPackage,
	id,
}) => {
	return (
		<div
			className={`select-none m-5 p-5 border-4 rounded-lg bg-white cursor-pointer text-right text-2xl flex flex-col gap-5 relative ${
				selectedPackage === id ? "border-primary-maroon" : ""
			}`}
			onClick={() => setSelectedPackage(id)}
		>
			<p>{name}</p>
			<p>عدد المرات: {limit} مرات</p>
			<p>السعر: {price}ج</p>
			{selectedPackage === id ? (
				<div className="absolute top-2 left-2 h-8 w-8 rounded-full bg-primary-green text-white flex justify-center items-center">
					<FontAwesomeIcon icon={faCheck} size="xs" />
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default Package;
