import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Package from "../components/Package";
import axios from "axios";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";

const Treasury = () => {
	const componentRef = useRef(null);
	const [packages, setPackages] = useState(null);
	const [selectedPackage, setSelectedPackage] = useState();
	const [packageError, setPackageError] = useState(false);
	const [creatingCodes, setCreatingCodes] = useState(false);
	const [codes, setCodes] = useState([]);
	const [print, setPrint] = useState(false);
	const printRef = useRef(null);
	const bulk = useRef(null);
	const resetForm = () => {
		bulk.current.value = "";
		setSelectedPackage(null);
	};
	const createCodes = () => {
		if (!selectedPackage) {
			setPackageError(true);
		} else {
			setCreatingCodes(true);
			setPackageError(false);
			const bulks = parseInt(bulk.current.value) || 1;

			const data = new FormData();
			data.append("package_id", selectedPackage);

			const requests = [];

			for (let i = 0; i < bulks; i++) {
				requests.push(axios.post("/add_code.php", data));
			}

			Promise.all(requests)
				.then((responses) => {
					const codesArr = [];
					responses.forEach((res) => {
						codesArr.push(res.data.message);
					});
					setCodes(codesArr);
					setCreatingCodes(false);
					setPrint(true);
					setTimeout(() => {
						printRef.current?.handlePrint();
					}, 500);
				})
				.catch((error) => {
					console.error("Error in one of the requests:", error);
				});
		}
	};
	useEffect(() => {
		axios
			.get("/get_packages.php")
			.then((res) => {
				setPackages(res.data.data);
			})
			.catch(() => {
				Swal.fire({
					title: "Something happend. Please contact us.",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
			});
	}, []);
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	const getTimeNow = () => {
		const now = new Date();
		return now.toLocaleString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: false,
		});
	};
	return (
		<div className="md:m-10 m-5 rounded-lg bg-primary-green h-full md:p-8 p-4 flex flex-col justify-between">
			{print && (
				<>
					<div ref={componentRef} className="flex flex-col">
						{codes.map((code, index) => (
							<React.Fragment key={code}>
								<div
									className={`h-[396.85px] flex flex-col justify-center ${
										index % 2 == 0
											? "border-b-2 border-b-black border-dashed"
											: ""
									}`}
								>
									<div className="text-right mx-3 flex flex-col gap-2">
										<p>:الاسم</p>
										<p>{`${getTimeNow()} :التاريخ`}</p>
										<p>
											{selectedPackage &&
												packages.filter(
													(pack) =>
														pack.id ===
														selectedPackage
												)[0].name}
										</p>
									</div>
									<Barcode
										className="mx-auto mt-5"
										value={code}
									/>
								</div>
							</React.Fragment>
						))}
					</div>
					<ReactToPrint
						trigger={() => <></>}
						content={() => componentRef.current}
						onAfterPrint={() => setPrint(false)}
						ref={printRef}
					/>
				</>
			)}
			<div>
				<p className="text-4xl text-white">Generate Code</p>
				<div className="md:w-[80%] my-10 mx-auto">
					{packages && (
						<Carousel responsive={responsive} rtl>
							{packages?.map((pack) => (
								<Package
									key={pack.id}
									name={pack.name}
									limit={pack.use_limit}
									price={pack.price}
									selectedPackage={selectedPackage}
									setSelectedPackage={setSelectedPackage}
									id={pack.id}
								/>
							))}
						</Carousel>
					)}
				</div>
				<p className="text-4xl text-white">Bulk Generate</p>
				<div className="flex md:justify-evenly items-center md:flex-nowrap flex-wrap md:my-0 my-5">
					<p className="text-3xl text-white">Enter Number of Codes</p>
					<input
						type="number"
						className="w-[400px] border-2 outline-none px-3 py-2 rounded-lg"
						placeholder="Leave Empty for one only"
						ref={bulk}
					/>
				</div>
			</div>
			{packageError && (
				<p className="text-primary-maroon text-2xl font-bold">
					* You must select a package
				</p>
			)}
			<div className="flex md:w-[85%] md:flex-nowrap flex-wrap justify-evenly mx-auto items-center md:gap-10 gap-5 text-white text-3xl text-bold">
				<button
					className="p-3 w-full bg-none border-2 border-white rounded-lg"
					onClick={resetForm}
				>
					Reset Form
				</button>
				<button
					className="p-3 w-full bg-primary-maroon border-2 border-primary-maroon rounded-lg"
					onClick={createCodes}
					disabled={creatingCodes}
				>
					{creatingCodes ? "Creating Code..." : "Proceed"}
				</button>
			</div>
		</div>
	);
};

export default Treasury;
