import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import UserContext from "../context/UserContex";

const Auth = () => {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		if (user !== "Not Yet") {
			if (user === 0) {
				navigate("/login");
			}
			if (user) {
				const path = location.pathname.split("/")[1].split("/")[0];
				if (user.role === "Adminstrator") {
					if (path === "") navigate("/dashboard");
					return;
				} else if (user.role === "QR Code" && path !== "qr-code")
					navigate("/qr-code");
				else if (user.role === "Treasury" && path !== "treasury")
					navigate("/treasury");
			}
		}
	});
	return <Outlet />;
};

export default Auth;
