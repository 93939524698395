import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const AddPackages = () => {
	const [submiting, setSubmiting] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [limitError, setLimitError] = useState(false);
	const [priceError, setPriceError] = useState(false);
	const addPackage = (e) => {
		e.preventDefault();
		setSubmiting(true);
		setNameError(false);
		setLimitError(false);
		setPriceError(false);
		const name = e.target.name.value;
		const limit = e.target.limit.value;
		const price = e.target.price.value;
		!name && setNameError(true);
		!limit && setLimitError(true);
		!price && setPriceError(true);
		if (!name || !limit || !price) {
			setSubmiting(false);
			return;
		}
		const data = new FormData();

		data.append("name", name);
		data.append("limit", limit);
		data.append("price", price);

		axios
			.post("/add_package.php", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				setSubmiting(false);
				if (res.data.status === "success") {
					Swal.fire({
						title: "Package Created!",
						icon: "success",
						confirmButtonColor: "#2E5339",
					});
				} else if (res.data.status === "error") {
					Swal.fire({
						title: "Please Try again!",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
				} else {
					Swal.fire({
						title: "Something happend. Please contact us.",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
				}
			})
			.catch(() => {
				setSubmiting(false);
				Swal.fire({
					title: "Something happend. Please contact us.",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
			});
	};
	return (
		<>
			<div className="flex justify-between border-b-2 border-b-slate-200 py-3 px-10">
				<p className="text-2xl font-bold">New Package</p>
			</div>
			<form className="p-5 flex flex-col gap-5" onSubmit={addPackage}>
				<div className="flex justify-between">
					<label className="text-xl md:w-auto w-full" htmlFor="name">
						Name
					</label>
					<input
						type="name"
						name="name"
						id="name"
						placeholder="Enter the name"
						className="outline-none border-2 border-slate-200 rounded-md w-[400px] px-2"
						onInput={(e) =>
							e.target.value !== "" && setNameError(false)
						}
					/>
				</div>
				{nameError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<div className="flex justify-between">
					<label className="text-xl md:w-auto w-full" htmlFor="limit">
						Limit
					</label>
					<input
						type="number"
						name="limit"
						id="limit"
						placeholder="Enter the limit"
						className="outline-none border-2 border-slate-200 rounded-md w-[400px] px-2"
						onInput={(e) =>
							e.target.value !== "" && setLimitError(false)
						}
					/>
				</div>
				{limitError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<div className="flex justify-between">
					<label className="text-xl md:w-auto w-full" htmlFor="price">
						Price
					</label>
					<input
						type="number"
						className="outline-none border-2 border-slate-200 rounded-md md:w-[400px] w-full px-2"
						name="price"
						id="price"
						placeholder="Enter the passwrod"
						onInput={(e) =>
							e.target.value !== "" && setPriceError(false)
						}
					/>
				</div>
				{priceError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<input
					type="submit"
					disabled={submiting}
					className="w-[200px] py-1 rounded-lg bg-primary-green text-white cursor-pointer"
					value={submiting ? "Adding Package..." : "Add Package"}
				/>
			</form>
		</>
	);
};

export default AddPackages;
