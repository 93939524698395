import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Layout from "./Layout";
import Treasury from "./pages/Treasury";
import Packages from "./pages/Packages";
import AddPackages from "./pages/AddPackages";
import Users from "./pages/Users";
import AddUsers from "./pages/AddUsers";
import QrCode from "./pages/QrCode";
import axios from "axios";
import Auth from "./utils/Auth";

axios.defaults.baseURL = "https://system.ramsis.online/API";

const App = () => {
	return (
		<HashRouter basename={process.env.PUBLIC_URL}>
			<Routes>
				<Route element={<Login />} path="/login" />
				<Route element={<Auth />}>
					<Route element={<Layout />}>
						<Route path="/" element={<Auth />} />
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/treasury" element={<Treasury />} />
						<Route path="/packages" element={<Packages />} />
						<Route path="/packages/add" element={<AddPackages />} />
						<Route path="/users" element={<Users />} />
						<Route path="/users/add" element={<AddUsers />} />
						<Route path="/qr-code" element={<QrCode />} />
					</Route>
				</Route>
			</Routes>
		</HashRouter>
	);
};

export default App;
