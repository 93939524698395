import {
	faBox,
	faChartLine,
	faClose,
	faPeopleGroup,
	faQrcode,
	faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../context/UserContex";

const Sidebar = ({ showMenu, setShowMenu, isPhone }) => {
	const { user } = useContext(UserContext);

	const sidebarItems = [
		{
			path: "/dashboard",
			label: "Dashboard",
			icon: faChartLine,
			roles: ["Adminstrator"],
		},
		{
			path: "/treasury",
			label: "Treasury",
			icon: faReceipt,
			roles: ["Adminstrator", "Treasury"],
		},
		{
			path: "/packages",
			label: "Packages",
			icon: faBox,
			roles: ["Adminstrator"],
		},
		{
			path: "/qr-code",
			label: "QR Code",
			icon: faQrcode,
			roles: ["Adminstrator", "QR Code"],
		},
		{
			path: "/users",
			label: "Users",
			icon: faPeopleGroup,
			roles: ["Adminstrator"],
		},
	];

	return (
		<div
			className={`bg-primary-green min-h-screen md:w-1/12 w-screen z-[10000] md:sticky ${
				showMenu ? "fixed" : "hidden"
			} top-0`}
		>
			<div className="md:hidden absolute text-white right-10 top-10 text-3xl cursor-pointer">
				<FontAwesomeIcon
					icon={faClose}
					onClick={() => setShowMenu(false)}
				/>
			</div>
			<img
				src="/images/icon-beige.png"
				className="px-5 py-2 md:h-auto h-[100px]"
				alt="Ramsis"
			/>
			<ul>
				{sidebarItems.map((item, index) => {
					// Check if the user role is allowed to view the item
					if (user && item.roles.includes(user.role)) {
						return (
							<li key={index}>
								<NavLink
									to={item.path}
									className={({ isActive }) =>
										"text-white flex flex-col justify-center items-center uppercase py-2 my-7" +
										(isActive
											? " bg-[rgb(255,255,255,0.2)] border-l-4"
											: "")
									}
									onClick={() =>
										isPhone && setShowMenu(false)
									}
								>
									<FontAwesomeIcon
										icon={item.icon}
										size="2x"
									/>
									<p>{item.label}</p>
								</NavLink>
							</li>
						);
					} else {
						return null;
					}
				})}
			</ul>
		</div>
	);
};

export default Sidebar;
