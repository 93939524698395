import { faBars, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import UserContext from "../context/UserContex";
import { useNavigate } from "react-router-dom";

const Navbar = ({ setShowMenu }) => {
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const logout = () => {
		setUser(null);
		localStorage.removeItem("user");
		sessionStorage.removeItem("user");
		navigate("/login");
	};
	return (
		<div className="shadow-md flex justify-between">
			<div className="md:hidden flex justify-center items-center p-5 text-2xl cursor-pointer">
				<FontAwesomeIcon
					icon={faBars}
					onClick={() => setShowMenu(true)}
				/>
			</div>
			<div className="flex justify-end p-3 w-full">
				<div className="flex justify-between items-center border-r-2 border-l-2 px-5 gap-5">
					<div className="flex flex-col">
						<p>{user?.username}</p>
						<p className="text-sm text-slate-500">{user?.role}</p>
					</div>
					<div className="h-[35px] w-[35px] bg-slate-700 rounded-full"></div>
				</div>
				<div className="px-5 flex justify-center items-center text-slate-500 text-xl">
					<FontAwesomeIcon
						icon={faPowerOff}
						className="cursor-pointer"
						onClick={logout}
					/>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
