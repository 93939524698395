import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Users = () => {
	const [users, setUsers] = useState(null);
	useEffect(() => {
		axios
			.get("/get_users.php")
			.then((res) => {
				if (res.data.status === "success") {
					setUsers(res.data.data);
				} else {
					Swal.fire({
						title: "Something happend. Please Contanct Us.",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
				}
			})
			.catch(() => {
				Swal.fire({
					title: "Connection Error!",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
			});
	}, []);
	const deleteUser = (e, user) => {
		e.target.style.pointerEvents = "none";
		const data = new FormData();
		data.append("user", user);
		axios
			.post("/delete_user.php", data)
			.then((res) => {
				if (res.data.status === "success") {
					setUsers((users) => users.filter((us) => us.user !== user));
				} else {
					Swal.fire({
						title: "Something happend. Please Contanct Us.",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
				}
				e.target.style.pointerEvents = "auto";
			})
			.catch(() => {
				Swal.fire({
					title: "Connection Error!",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
				e.target.style.pointerEvents = "auto";
			});
	};
	return (
		<>
			<div className="flex justify-between border-b-2 border-b-slate-200 py-3 px-10">
				<Link
					to="/users/add"
					className="py-1 px-9 rounded-lg bg-primary-green text-white flex items-center"
				>
					<FontAwesomeIcon icon={faPlus} className="mr-2 text-sm" />
					Add User
				</Link>
			</div>
			<div className="py-5 px-5 md:px-10">
				<p className="text-2xl font-bold">Team</p>
				<div className="py-3">
					<p className="text-slate-500">Employe</p>
					<p className="text-2xl font-bold">{users?.length}</p>
				</div>
				<div className="relative overflow-x-auto md:p-10 md:w-auto md:m-0 my-5 mx-auto w-[100%]">
					<table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-md overflow-hidden">
						<thead className="text-white text-xl bg-primary-green">
							<tr>
								<th scope="col" className="px-6 py-3">
									Employee
								</th>
								<th scope="col" className="px-6 py-3">
									Role
								</th>
								<th
									scope="col"
									className="px-6 py-3 text-center"
								>
									Delete
								</th>
							</tr>
						</thead>
						<tbody className="text-[1rem]">
							{users?.map((user) => (
								<tr
									key={user.id}
									className="bg-white border-b font-medium text-gray-600"
								>
									<th
										scope="row"
										className="px-6 py-4 whitespace-nowrap"
									>
										{user.name}
									</th>
									<th
										scope="row"
										className="px-6 py-4 whitespace-nowrap"
									>
										{user.role}
									</th>
									<th
										scope="row"
										className="px-6 py-4 whitespace-nowrap text-center"
									>
										<FontAwesomeIcon
											icon={faClose}
											className="text-primary-maroon font-bold text-xl cursor-pointer"
											onClick={(e) =>
												deleteUser(e, user.user)
											}
										/>
									</th>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Users;
