import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";

const AddUsers = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [submiting, setSubmiting] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [usernameError, setUsernameError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [roleError, setroleError] = useState(false);
	const addUser = (e) => {
		e.preventDefault();
		setSubmiting(true);
		setNameError(false);
		setUsernameError(false);
		setPasswordError(false);
		setroleError(false);
		const username = e.target.username.value;
		const name = e.target.name.value;
		const password = e.target.password.value;
		const role = e.target.role.value;
		!name && setNameError(true);
		!username && setUsernameError(true);
		!password && setPasswordError(true);
		!role && setroleError(true);
		if (!name || !username || !password || !role) {
			setSubmiting(false);
			return;
		}
		const data = new FormData();

		data.append("name", name);
		data.append("user", username);
		data.append("password", password);
		data.append("role", role);

		axios
			.post("/add_user.php", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				setSubmiting(false);
				if (res.data.status === "success") {
					Swal.fire({
						title: "User Created!",
						icon: "success",
						confirmButtonColor: "#2E5339",
					});
				} else if (res.data.status === "error") {
					Swal.fire({
						title: "Please Try again!",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
				} else {
					Swal.fire({
						title: "Something happend. Please contact us.",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
				}
			})
			.catch(() => {
				setSubmiting(false);
				Swal.fire({
					title: "Something happend. Please contact us.",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
			});
	};
	return (
		<>
			<div className="flex justify-between border-b-2 border-b-slate-200 py-3 px-10">
				<p className="text-2xl font-bold">New User</p>
			</div>
			<form className="p-5 flex flex-col gap-5" onSubmit={addUser}>
				<div className="flex justify-between">
					<label className="text-xl md:w-auto w-full" htmlFor="name">
						Name
					</label>
					<input
						type="name"
						name="name"
						id="name"
						placeholder="Enter the name"
						className="outline-none border-2 border-slate-200 rounded-md w-[400px] px-2"
						onInput={(e) =>
							e.target.value !== "" && setNameError(false)
						}
					/>
				</div>
				{nameError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<div className="flex justify-between">
					<label
						className="text-xl md:w-auto w-full"
						htmlFor="username"
					>
						Username
					</label>
					<input
						type="text"
						name="username"
						id="username"
						placeholder="Enter the username"
						className="outline-none border-2 border-slate-200 rounded-md w-[400px] px-2"
						onInput={(e) =>
							e.target.value !== "" && setUsernameError(false)
						}
					/>
				</div>
				{usernameError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<div className="flex justify-between">
					<label
						className="text-xl md:w-auto w-full"
						htmlFor="password"
					>
						Password
					</label>
					<div className="relative md:w-auto w-full">
						<input
							type={showPassword ? "text" : "password"}
							className="outline-none border-2 border-slate-200 rounded-md md:w-[400px] w-full px-2"
							name="password"
							id="password"
							placeholder="Enter the passwrod"
							onInput={(e) =>
								e.target.value !== "" && setPasswordError(false)
							}
						/>
						<FontAwesomeIcon
							icon={showPassword ? faEyeSlash : faEye}
							className="absolute right-3 top-1/2 translate-y-[-50%] cursor-pointer"
							onClick={() => setShowPassword(!showPassword)}
						/>
					</div>
				</div>
				{passwordError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<div className="flex justify-between">
					<label className="text-xl md:w-auto w-full" htmlFor="role">
						Role
					</label>
					<select
						name="role"
						id="role"
						className="outline-none border-2 border-slate-200 rounded-md w-[400px] px-2"
						onChange={(e) =>
							e.target.value !== "" && setNameError(false)
						}
					>
						<option value="Adminstrator">Adminstrator</option>
						<option value="QR Code">QR Code</option>
						<option value="Treasury">Treasury</option>
					</select>
				</div>
				{roleError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<input
					type="submit"
					disabled={submiting}
					className="w-[200px] py-1 rounded-lg bg-primary-green text-white cursor-pointer"
					value={submiting ? "Adding User..." : "Save"}
				/>
			</form>
		</>
	);
};

export default AddUsers;
