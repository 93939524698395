import React, { useEffect, useRef, useState } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	CartesianGrid,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheck,
	faFileInvoiceDollar,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Dashboard = () => {
	const [chartData, setChartData] = useState([]);
	const [range, setRange] = useState("year");
	const [boxes, setBoxes] = useState([]);

	useEffect(() => {
		const handleChartData = (input) => {
			const data = [];
			const today = new Date();

			const initData = () => {
				if (range === "year") {
					for (let i = 0; i < 12; i++) {
						data.push({
							date: new Date(
								today.getFullYear(),
								i,
								1
							).toLocaleDateString("en-US", { month: "short" }),
							EGP: 0,
						});
					}
				} else if (range === "month") {
					const totalDays = new Date(
						today.getFullYear(),
						today.getMonth() + 1,
						0
					).getDate();
					for (let i = 1; i <= totalDays; i++) {
						data.push({
							date: new Date(
								today.getFullYear(),
								today.getMonth(),
								i
							).toLocaleDateString("en-US", { day: "numeric" }),
							EGP: 0,
						});
					}
				} else if (range === "week") {
					const startDate = new Date(today);
					startDate.setDate(today.getDate() - today.getDay());

					for (let i = 0; i < 7; i++) {
						const day = new Date(startDate);
						day.setDate(startDate.getDate() + i);
						data.push({
							date: day.toLocaleDateString("en-US", {
								day: "numeric",
							}),
							EGP: 0,
						});
					}
				} else if (range === "day") {
					data.push({
						date: today.toLocaleDateString("en-US", {
							day: "numeric",
						}),
						EGP: 0,
					});
				}
			};

			initData();

			input.data.forEach((o) => {
				let date;

				if (range === "year") {
					const month = parseInt(o.month, 10) - 1;
					date = new Date(
						today.getFullYear(),
						month,
						1
					).toLocaleDateString("en-US", { month: "short" });
				} else if (range === "month") {
					const day = parseInt(o.day, 10);
					date = new Date(
						today.getFullYear(),
						today.getMonth(),
						day
					).toLocaleDateString("en-US", { day: "numeric" });
				} else if (range === "week") {
					const dayOffset = parseInt(o.day, 10);
					const startDate = new Date(today);
					startDate.setDate(today.getDate() - 7);
					date = new Date(startDate);
					date.setDate(date.getDate() + dayOffset);
					date = date.toLocaleDateString("en-US", { day: "numeric" });
				} else if (range === "day") {
					date = today.toLocaleDateString("en-US", {
						day: "numeric",
					});
				}

				const index = data.findIndex((d) => d.date === date);
				if (index !== -1) {
					data[index].EGP = parseInt(o.total, 10);
				}
			});

			setChartData(data);
		};

		const endpoints = [
			"/get_today_earnings.php",
			"/get_today_payments.php",
			"/get_checks.php",
		];

		const requests = endpoints.map((endpoint) => axios.get(endpoint));
		const data = new FormData();
		data.append("period", range);
		requests.push(axios.post("/get_earnings_interval.php", data));

		Promise.all(requests).then((responses) => {
			setBoxes(responses.map((res) => res.data));
			handleChartData(responses[3].data);
		});
	}, [range]);

	const changeInterval = (e) => {
		setRange(e.target.value);
	};

	const chartWrapper = useRef(null);

	return (
		<div className="h-full w-[90%] mx-auto flex flex-col justify-between gap-5 my-10">
			<div className="flex justify-between items-stretch md:gap-20 gap-10 md:flex-nowrap flex-wrap">
				<div className="flex rounded-md bg-primary-green items-center px-3 py-5 gap-10 flex-1 justify-between">
					<div className="flex flex-col text-white">
						<p className="text-4xl">Today Income</p>
						<p className="text-3xl">{boxes[0]?.data || 0} EGP</p>
					</div>
					<FontAwesomeIcon
						icon={faFileInvoiceDollar}
						size="5x"
						className="text-[rgb(255,255,255,0.33)]"
					/>
				</div>
				<div className="flex rounded-md bg-primary-green items-center px-3 py-5 gap-10 flex-1 justify-between">
					<div className="flex flex-col text-white">
						<p className="text-4xl">Students</p>
						<p className="text-3xl">
							{boxes[1]?.data || 0} Student
						</p>
					</div>
					<FontAwesomeIcon
						icon={faUsers}
						size="5x"
						className="text-[rgb(255,255,255,0.33)]"
					/>
				</div>
				<div className="flex rounded-md bg-primary-green items-center px-3 py-5 gap-10 flex-1 justify-between">
					<div className="flex flex-col text-white">
						<p className="text-4xl">Today</p>
						<p className="text-3xl">{boxes[2]?.data || 0} Check</p>
					</div>
					<FontAwesomeIcon
						icon={faCheck}
						size="5x"
						className="text-[rgb(255,255,255,0.33)]"
					/>
				</div>
			</div>
			<div className="md:p-10 p-5 flex flex-col gap-10 border-2 border-slate-300 rounded-lg">
				<div className="flex justify-between items-center">
					<p className="text-2xl font-bold">Statistics</p>
					<select
						className="md:w-[300px] w-[150px] outline-none px-3 text-left py-2 border-2 border-slate-200 rounded-full"
						onChange={changeInterval}
						value={range}
					>
						<option value="year">Year</option>
						<option value="month">Month</option>
						<option value="week">Week</option>
						<option value="day">Day</option>
					</select>
				</div>
				<div style={{ width: "100%" }} ref={chartWrapper}>
					{chartWrapper.current && (
						<BarChart
							width={chartWrapper.current?.clientWidth}
							height={500}
							data={chartData}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date" />
							<YAxis />
							<Tooltip />
							<Legend />
							<Bar dataKey="EGP" fill="#2E5339" />
						</BarChart>
					)}
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
