import { faClose, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Packages = () => {
	const [packages, setPackages] = useState(null);
	const [search, setSearch] = useState("");
	useEffect(() => {
		axios
			.get("/get_packages.php")
			.then((res) => {
				setPackages(res.data.data);
			})
			.catch(() => {
				Swal.fire({
					title: "Something happend. Please contact us.",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
			});
	}, []);
	const deletePackage = (e, id) => {
		console.log(e.target);

		e.target.style.pointerEvents = "none";
		const data = new FormData();

		data.append("id", id);
		axios
			.post("/delete_package.php", data)
			.then((res) => {
				if (res.data.status === "success") {
					Swal.fire({
						title: "Package Deleted!",
						icon: "success",
						confirmButtonColor: "#2E5339",
					});
					setPackages((pack) => pack.filter((p) => p.id !== id));
				} else {
					Swal.fire({
						title: "Something happend. Please Contanct Us.",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
				}
				e.target.style.pointerEvents = "auto";
			})
			.catch(() => {
				Swal.fire({
					title: "Connection Error!",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
				e.target.style.pointerEvents = "auto";
			});
	};
	return (
		<>
			<div className="flex justify-between border-b-2 border-b-slate-200 py-3 md:px-10 px-3 md:flex-nowrap md:gap-0 gap-5 flex-wrap">
				<Link
					to="/packages/add"
					className="py-1 px-9 rounded-lg bg-primary-green text-white flex items-center"
				>
					<FontAwesomeIcon icon={faPlus} className="mr-2 text-sm" />
					Add Packages
				</Link>
				<div className="relative md:w-auto w-full">
					<input
						type="text"
						className="p-1 md:w-[400px] w-full rounded-lg border-2 border-slate-200 outline-none"
						placeholder="Search.."
						onInput={(e) =>
							setSearch(e.target.value.toLowerCase().trim())
						}
					/>
					<FontAwesomeIcon
						icon={faSearch}
						className="text-sm absolute right-2 top-[50%] translate-y-[-50%] text-slate-400 outline-none"
					/>
				</div>
			</div>

			<div className="relative overflow-x-auto md:p-10 md:w-auto md:m-0 my-5 mx-auto w-[90%]">
				<table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-md overflow-hidden">
					<thead className="text-white text-xl bg-primary-maroon">
						<tr>
							<th scope="col" className="px-6 md:py-3">
								Package Name
							</th>
							<th scope="col" className="px-6 md:py-3">
								Price
							</th>
							<th scope="col" className="px-6 md:py-3">
								Number of Codes
							</th>
							<th scope="col" className="px-6 md:py-3">
								Limit
							</th>
							<th scope="col" className="px-6 md:py-3">
								Income
							</th>
							<th
								scope="col"
								className="px-6 md:py-3 text-center"
							>
								Delete
							</th>
						</tr>
					</thead>
					<tbody className="text-[1rem]">
						{packages
							?.filter((pack) =>
								pack.name.toLowerCase().includes(search)
							)
							.map((pack) => (
								<tr
									key={pack.id}
									className="bg-white border-b font-medium text-gray-600"
								>
									<th
										scope="row"
										className="px-6 py-4 whitespace-nowrap"
									>
										{pack.name}
									</th>
									<td className="px-6 py-4">{pack.price}</td>
									<td className="px-6 py-4">
										{pack.code_count}
									</td>
									<td className="px-6 py-4">
										{pack.use_limit}
									</td>
									<td className="px-6 py-4 font-bold">
										{pack.code_count * pack.price}
									</td>
									<td className="px-6 py-4 font-bold text-center text-primary-maroon">
										<FontAwesomeIcon
											icon={faClose}
											className="cursor-pointer"
											onClick={(e) =>
												deletePackage(e, pack.id)
											}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Packages;
