import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";

const Layout = () => {
	const [showMenu, setShowMenu] = useState(null);
	const [isPhone, setIsPhone] = useState(null);
	useEffect(() => {
		setShowMenu(window.innerWidth >= 640);
		setIsPhone(window.innerWidth <= 640);
		const handleResize = () => {
			setShowMenu(window.innerWidth >= 640);
			setIsPhone(window.innerWidth <= 640);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<div className="flex">
			<Sidebar
				showMenu={showMenu}
				setShowMenu={setShowMenu}
				isPhone={isPhone}
			/>
			<div className="md:w-11/12 w-full min-h-screen flex flex-col">
				<Navbar setShowMenu={setShowMenu} />
				<Outlet />
			</div>
		</div>
	);
};

export default Layout;
