import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import UserContext from "../context/UserContex";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [submiting, setSubmiting] = useState(false);
	const [usernameError, setUsernameError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [loginError, setLoginError] = useState(null);
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const redirec_to_role = (user) => {
		if (user.role === "Adminstrator") {
			navigate("/dashboard");
		} else if (user.role === "QR Code") {
			navigate("/qr-code");
		} else if (user.role === "Treasury") {
			navigate("/treasury");
		}
	};

	useEffect(() => {
		if (user) {
			redirec_to_role(user);
		}
	});
	const loginHandler = (e) => {
		e.preventDefault();
		setSubmiting(true);
		setLoginError(null);
		setUsernameError(false);
		setPasswordError(false);
		const username = e.target.username.value;
		const password = e.target.password.value;
		const remeber = e.target.remeber.checked;
		!username && setUsernameError(true);
		!password && setPasswordError(true);
		if (!username || !password) {
			setSubmiting(false);
			return;
		}
		const data = new FormData();

		data.append("user", username);
		data.append("password", password);

		axios
			.post("/login_user.php", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				if (res.data.status === "error") {
					setLoginError(res.data.message);
					setSubmiting(false);
				} else if (res.data.status === "success") {
					setSubmiting(false);
					setUser(res.data.data);
					if (remeber) {
						localStorage.setItem(
							"user",
							JSON.stringify(res.data.data)
						);
					} else {
						sessionStorage.setItem(
							"user",
							JSON.stringify(res.data.data)
						);
					}
					redirec_to_role(res.data.data);
				}
			});
	};
	return (
		<div className="flex justify-center items-center h-screen w-full flex-col">
			<img
				src="/images/login.png"
				alt="Ramsis"
				className="md:w-1/4 w-[70%]"
			/>
			<form
				className="md:w-[30%] w-[80%] flex flex-col gap-5 mt-5"
				onSubmit={loginHandler}
			>
				<div className="flex flex-col gap-2">
					<label htmlFor="username" className="font-bold text-xl">
						Username
					</label>
					<input
						type="text"
						className="w-full border-2 outline-none px-2 py-3 rounded-lg"
						name="username"
						id="username"
						placeholder="Enter Username"
						onInput={(e) =>
							e.target.value !== "" && setUsernameError(false)
						}
					/>
				</div>
				{usernameError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<div className="flex flex-col gap-2">
					<label htmlFor="password" className="font-bold text-xl">
						Passwrod
					</label>
					<div className="relative">
						<input
							type={showPassword ? "text" : "password"}
							className="w-full border-2 outline-none px-2 py-3 rounded-lg"
							name="password"
							id="password"
							placeholder="Enter Passwrod"
							onInput={(e) =>
								e.target.value !== "" && setPasswordError(false)
							}
						/>
						<FontAwesomeIcon
							icon={showPassword ? faEyeSlash : faEye}
							className="absolute right-3 top-1/2 translate-y-[-50%] cursor-pointer"
							onClick={() => setShowPassword(!showPassword)}
						/>
					</div>
				</div>
				{passwordError && (
					<p className="text-primary-maroon">
						* This Field is required
					</p>
				)}
				<div className="flex gap-2">
					<input type="checkbox" name="remeber" id="remeber" />
					<label htmlFor="remeber">Keep me logged in</label>
				</div>
				{loginError && (
					<p className="text-primary-maroon font-bold">
						* {loginError}
					</p>
				)}
				<input
					type="submit"
					disabled={submiting}
					className="w-full p-3 rounded cursor-pointer text-sm mt-3 text-white bg-primary-green"
					value={submiting ? "Logging..." : "Log In"}
				/>
			</form>
		</div>
	);
};

export default Login;
