import axios from "axios";
import React, { useState } from "react";
import BarcodeReader from "react-barcode-reader";
import Swal from "sweetalert2";

const QrCode = () => {
	const [result, setResult] = useState(null);
	const [submit, setSubmit] = useState(false);
	const [response, setResponse] = useState(null);
	const [image, setImage] = useState(null);

	const countdown_and_reset = () => {
		setTimeout(() => {
			setResult(null);
			setSubmit(false);
			setResponse(null);
			setImage(null);
		}, 2000);
	};

	const handleScan = (code) => {
		setResult(code);
		setSubmit(true);
		const data = new FormData();
		data.append("code", code);
		axios
			.post("/read_code.php", data)
			.then((res) => {
				if (res.data.status) {
					setSubmit(true);
					setResponse(res.data.message);
					if (res.data.status === "sucess") {
						setImage("success");
					} else if (res.data.status === "error") {
						setImage("error");
					}
				} else {
					Swal.fire({
						title: "Code Error!",
						icon: "error",
						confirmButtonColor: "#2E5339",
					});
					setImage("error");
				}
				countdown_and_reset();
			})
			.catch(() => {
				Swal.fire({
					title: "Code Error!",
					icon: "error",
					confirmButtonColor: "#2E5339",
				});
				setImage("error");
				countdown_and_reset();
			});
	};

	const handleError = (err) => {
		console.error(err);
	};
	return (
		<div className="h-full flex flex-col justify-center items-center">
			{image && (
				<img
					src={`/images/${image}.svg`}
					alt={image}
					className="mb-10 h-52"
				/>
			)}
			<BarcodeReader onError={handleError} onScan={handleScan} />
			<p className="text-4xl font-bold text-center">
				{result || "Waiting for scanning code"}
			</p>
			{submit && (
				<p className="text-4xl font-bold text-center">
					{response || "Checking Code..."}
				</p>
			)}
		</div>
	);
};

export default QrCode;
